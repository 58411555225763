import React, { useState, useEffect } from "react";
import "./PastTimers.css";
import '../SearchContainer.css';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField
} from "@mui/material";
import dayjs from "dayjs";


const PastTimers = ({ pastTimersToggle }) => {
  const [pastTimers, setPastTimers] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState(dayjs());
  const [filteredReports, setfilteredReports] = useState([]);


  const handleSearchByName = (event) => {

    setSearchName(event.target.value);
    const filteredReports = pastTimers.filter(report =>
      report.name.toLowerCase().includes(searchName.toLowerCase())
    );
    setfilteredReports(filteredReports);
    // setReportList(filteredReports);
    // if (searchName == '' ) {
    //   setReportList(reportListArray);
    // }
  };

  const handleSearchByDate = (date) => {
    let searchMonth = parseInt(date.month());
    let searchDay = parseInt(date.date());
    let searchYear = parseInt(date.year());

    if (searchYear < 100) {
      searchYear += 2000;
    }

    let esearching = new Date(searchYear, searchMonth, searchDay);
    console.log("Esearching : ", esearching);
    setSearchDate(date);
    const filteredReports = pastTimers.filter(report => {
      const reportDate = dayjs(report.date);
      return reportDate.isSame(dayjs(date), 'day');
    });
    console.log(filteredReports);
    setfilteredReports(filteredReports);
  };

  useEffect(() => {
    const fetchTimers = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/getAllTimers");
        if (!response.ok) throw new Error("Failed to fetch");
        const data = await response.json();
        setPastTimers(data); // Assuming this sets the state correctly
      } catch (error) {
        console.error("Error fetching timer data:", error);
      }
    };

    if (pastTimersToggle) {
      fetchTimers();
    }
  }, [pastTimersToggle]);

  return (
    <div>
      <div className="search-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div class="night">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>

        <div className="search-fields" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "50px", width: "100%" }}>
          <TextField
            sx={{
              backgroundColor: "#4484FF",
              borderRadius: "10px",
              '& .MuiInputLabel-root': {
                color: '#ffad3d',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffad3d',
                },
                '&:hover fieldset': {
                  borderColor: '#ffad3d',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ffad3d',
                },
                '& input': {
                  color: '#ffad3d',
                },
              },
            }}
            onChange={handleSearchByName}
            className="search-input"
            label="Search By Name"
            variant="outlined"
          />

          <TextField
            label="Search By Date"
            type="date"
            sx={{
              backgroundColor: "#4484FF",
              borderRadius: "10px",
              '& .MuiInputLabel-root': {
                color: '#ffad3d',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffad3d',
                },
                '&:hover fieldset': {
                  borderColor: '#ffad3d',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ffad3d',
                },
                '& input': {
                  color: '#ffad3d',
                },
              },
            }}
            InputLabelProps={{ shrink: true }}
            value={searchDate ? dayjs(searchDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => handleSearchByDate(dayjs(e.target.value))}
          />
        </div>
      </div>
      <TableContainer component={Paper} className="timerContainer">
        <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
          PAST TIMERS
        </h4>
        <Table sx={{ minWidth: 650 }} className="leaveTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Name</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Email</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Date</span>
              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                <span className="myTableHeadSpan">Timer</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {/* (filteredReports.length > 0 ? filteredReports : leaveRows).map((leave) => ( */}
            {(filteredReports.length > 0 ? filteredReports : pastTimers).map((timer) => (
              <TableRow key={timer._id}>
                <TableCell sx={{ fontSize: "large" }}>{timer.name}</TableCell>
                <TableCell sx={{ fontSize: "large" }}>{timer.email}</TableCell>
                <TableCell sx={{ fontSize: "large" }}>
                  {new Date(timer.date).toLocaleString()}
                </TableCell>
                <TableCell className="timerCell" align="right">
                  {timer.time}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PastTimers;
