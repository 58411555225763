import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
          .then((registration) => {
              console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch((error) => {
              console.log('Service Worker registration failed:', error);
          });
  });
}


  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/service-worker.js', { scope: '/Employee/' })
  //     .then((registration) => {
  //       console.log('Service Worker registered with scope:', registration.scope);
  //     })
  //     .catch((error) => {
  //       console.log('Service Worker registration failed:', error);
  //     });
  // }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

