import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "./navbar.css";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, Button } from "@mui/material";

const MyNavbar = ({ user }) => {
  const [Employee, setEmployee] = useState(null);

  useEffect(() => {
    const loginEmployee = async () => {
      try {
        const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
        const data = await response.json();

        if (response.ok) {
          console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error('Login failed:', data.message);
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    };

    loginEmployee();
  }, [user.email]);

  const handleLogout = () => {
    console.log(user);
    localStorage.removeItem("userValue");
    window.location.reload();
  };

  return (
    <div className="header">
      <Navbar className="bg-body-tertiary navbar">
        <Avatar
          sx={{
            bgcolor: "black",
            marginRight: "10px",
            fontSize: 'x-large',
            width: "60px",
            height: "60px",
          }}
          src={Employee?.profilepic !== "https://via.placeholder.com/250" ? Employee?.profilepic : null}
        >
          {Employee?.profilepic === "https://via.placeholder.com/250" && user.name[0]}
        </Avatar>
        <div className="profile">
          <span>{user.name}</span>
          <span>{user.role}</span>
        </div>
        <button className="logoutBtn">
          <LogoutIcon onClick={handleLogout} />
        </button>
      </Navbar>
    </div>
  );
};

export default MyNavbar;
