// src/Sidebar.js
import React from "react";
import logo from "../../../utilities/logo-png.png";
import "./sidebarr.css";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

const Sidebar = (props) => {
  const myProfileClass = props.myProfileToggle ? "activeNav" : "deactivateNav";
  const myDashboardClass = props.timerShowToggle ? "activeNav" : "deactivateNav";
  const eventsClass = props.eventsToggle ? "activeNav" : "deactivateNav";
  const requestLeaveClass = props.requestLeaveToggle ? "activeNav" : "deactivateNav";


  return (
    <div className="sidebar-space">
      <div className="sidebar">
        <div className="side-content">
          <div className="logo">
            <img
              src={logo}
              width="120"
              height="120"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span>Growth Guild</span>
          </div>
          <div className="buttons">
            {/* EMPTY */}
            <button
              className={`navButton ${myDashboardClass}`}
              onClick={props.onShowTimer}
            >
              <div>@ Dashboard</div>
            </button>
            <button
              className={`navButton ${myProfileClass}`}
              onClick={props.onShowProfile}
            >
              <div>@ My Profile</div>
            </button>
            <button
              className={`navButton ${eventsClass}`}
              onClick={props.onShowEvents}
            >
              <div>@ Upcoming Events</div>
            </button>
            <button
              className={`navButton ${requestLeaveClass}`}
              onClick={props.onShowRequestLeave}
            >
              <div>@ Request A leave</div>
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
