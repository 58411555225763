import React, { useState, useEffect } from "react";
import "./App.css";
import "./Global.css";
import "./color.css";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./components/LoginPage/login";
import Hr from "./components/HR/hr";
import Employee from "./components/Employee/employee";
import Manager from "./components/Management/manage";
import Executive from "./components/Executive/executive";

import logo from "./utilities/logo-white.jpeg";
import logopng from "./utilities/logo-png.png";
import { jwtDecode } from "jwt-decode";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function processTokenInURL() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (params.has("token")) {
      const tokenValue = params.get("token");
      localStorage.setItem("userValue", tokenValue);
      setUser(jwtDecode(tokenValue));

      params.delete("token");
      url.search = params.toString();
      window.history.pushState({}, "", url.toString());
    } else {
      const read_token = localStorage.getItem("userValue") || "";
      if (read_token) {
        setUser(jwtDecode(read_token));
      }
    }
    setTimeout(() => {
      setLoading(false); // Set loading to false after the delay
    }, 3000);
  }

  // Update window width on resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    processTokenInURL();
  }, []);

  if (loading) {
    return (
      <div className="preloader">
        <div id="handle-preloader" className="handle-preloader">
          <div className="animation-preloader">
            <div className="spinner"></div>
            <div className="txt-loading">
              <span data-text-preloader="G" className="letters-loading">G</span>
              <span data-text-preloader="R" className="letters-loading">R</span>
              <span data-text-preloader="O" className="letters-loading">O</span>
              <span data-text-preloader="W" className="letters-loading">W</span>
              <span data-text-preloader="T" className="letters-loading">T</span>
              <span data-text-preloader="H" className="letters-loading">H</span>
              <span data-text-preloader="G" className="letters-loading letters-loading-spaced">G</span>
              <span data-text-preloader="U" className="letters-loading">U</span>
              <span data-text-preloader="I" className="letters-loading">I</span>
              <span data-text-preloader="L" className="letters-loading">L</span>
              <span data-text-preloader="D" className="letters-loading">D</span>
              <span data-text-preloader="H" className="letters-loading letters-loading-spaced">H</span>
              <span data-text-preloader="R" className="letters-loading">R</span>
              <span data-text-preloader="M" className="letters-loading">M</span>
              <span data-text-preloader="S" className="letters-loading">S</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (windowWidth < 1000) {
    return (
      <div style={{
        backgroundColor: "#FFFFFF",
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <img src={logopng} alt="Logo" style={{ width: "190px", height: "200px", marginBottom: "20px" }} />
        <h1 className="heading" style={{ textAlign: "center" }}>Please switch to a desktop screen to view the full content</h1>
      </div>
    );
  }

  const myRouter = createBrowserRouter([
    {
      path: "/",
      element:
        user !== null ? (
          user.role === "HR" ? (
            <Navigate to="/hr" />
          ) : user.role === "Executive" ? (
            <Navigate to="/executive" />
          ) : (
            <Navigate to="/employee" />
          )
        ) : (
          <Login />
        ),
    },
    {
      path: "/employee",
      element:
        user && user.role === "Employee" ? (
          <Employee user={user} />
        ) : (
          <Navigate to="/" />
        ),
    },
    {
      path: "/executive",
      element:
        user && user.role === "Executive" ? (
          <Executive user={user} />
        ) : (
          <Navigate to="/" />
        ),
    },
    {
      path: "/hr",
      element:
        user && user.role === "HR" ? <Hr user={user} /> : <Navigate to="/" />,
    },
    {
      path: "/login",
      element: user ? <Navigate to="/" /> : <Login />,
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);

  return <RouterProvider router={myRouter} />;
}

export default App;
