import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent , DialogTitle, TextField, Autocomplete } from '@mui/material';
import dayjs from 'dayjs';

function NewRetainer(props) {
    const [clientName, setClientName] = useState("");
    const [retainers, setRetainers] = useState([]);
    const [selectedRetainer, setSelectedRetainer] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [startingDate, setStartingDate] = useState(dayjs().format('YYYY-MM-DD')); // Default to today's date

    const handleCreate = async () => {
        if (clientName) {
            try {
                const response = await fetch('https://hr-backend-72v1.onrender.com/createretainer', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ projectType: 'Retainer', clientName: clientName }),
                });
                const data = await response.json();
                if (response.ok) {
                    alert('Retainer Created Successfully');
                    console.log('Retainer created:', data);
                    props.handleCloseRetainerAdd();
                } else {
                    alert('Retainer Could not be created');
                    throw new Error(data.message || "Failed to create retainer");
                }
            } catch (error) {
                alert('Retainer Could not be created');
                console.error('Error creating retainer:', error);
            }
        }
    };

    useEffect(() => {
        fetch('https://hr-backend-72v1.onrender.com/api/retainers/names')
            .then(res => res.json())
            .then(setRetainers)
            .catch(console.error);
    }, []);

    const handleAddEmployee = async () => {
        if (selectedRetainer && selectedEmployee && startingDate) {
            const response = await fetch('https://hr-backend-72v1.onrender.com/api/retainers/by-name/addemployee', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    clientName: selectedRetainer.clientName,
                    employee: {
                        name: selectedEmployee.name,
                        email: selectedEmployee.email,
                        startingDate: startingDate // Include the starting date in the payload
                    }
                })
            });
    
            if (response.ok) {
                alert('Employee added successfully!');
                props.handleCloseRetainerEmployeeAdd(); 
            } else {
                const errorData = await response.json();
                alert(`Failed to add employee: ${errorData.message}`);
            }
        }
    };

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleCloseRetainerAdd}>
                <DialogTitle>Create New Retainer</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="clientName"
                        label="Client Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCloseRetainerAdd}>Cancel</Button>
                    <Button onClick={handleCreate} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={props.openRetainerEmployeeAdd} onClose={props.handleCloseRetainerEmployeeAdd}>
                <DialogContent>
                    <Autocomplete
                        options={retainers}
                        getOptionLabel={option => option.clientName}
                        style={{ width: 300, marginBottom: 20 }}
                        onChange={(event, newValue) => setSelectedRetainer(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Retainer" />}
                    />
                    <Autocomplete
                        options={props.emails}
                        getOptionLabel={option => `${option.name} (${option.email})`}
                        style={{ width: 300, marginBottom: 20 }}
                        onChange={(event, newValue) => setSelectedEmployee(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Employee" />}
                    />
                    <TextField
                        label="Starting Date"
                        type="date"
                        fullWidth
                        value={startingDate}
                        onChange={(e) => setStartingDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCloseRetainerEmployeeAdd}>Cancel</Button>
                    <Button onClick={handleAddEmployee} color="primary">Add Employee</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewRetainer;
