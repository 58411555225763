import React, { useState, useEffect } from "react";
import "./ProgressReport.css";
import '../SearchContainer.css';

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  TableContainer,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../../utilities/logo-png.png";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ProgressRep = ({ progressReportsToggle }) => {
  const [reportList, setReportList] = useState([]);
  const [reportListArray, setReportListArray] = useState([]);
  const [filteredReports, setfilteredReports] = useState([]);
  // const [currentReportDetail, setCurrentReportDetail] = useState("");
  const [dialogContent, setDialogContent] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState(dayjs());


  const handleSearchByName = (event) => {

    setSearchName(event.target.value);
    const filteredReports = reportList.filter(report =>
      report.name.toLowerCase().includes(searchName.toLowerCase())
    );
    setfilteredReports(filteredReports);
    // setReportList(filteredReports);
    // if (searchName == '' ) {
    //   setReportList(reportListArray);
    // }
  };

  const handleSearchByDate = (date) => {
    let searchMonth = parseInt(date.month());
    let searchDay = parseInt(date.date());
    let searchYear = parseInt(date.year());

    if (searchYear < 100) {
      searchYear += 2000;
    }

    let esearching = new Date(searchYear, searchMonth, searchDay);
    console.log("Esearching : ", esearching);

    const filteredReports = reportList.filter(report => {
      const reportDate = dayjs(report.date);
      return reportDate.isSame(dayjs(date), 'day');
    });
    console.log(filteredReports);
    setfilteredReports(filteredReports);
    setSearchDate(date);
  };


  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchProgressReports = async () => {
      try {
        const response = await fetch("https://hr-backend-72v1.onrender.com/reportshow");
        const data = await response.json();
        setReportList(data);
        setReportListArray(data);
        // setIsTableVisible(true);
      } catch (error) {
        console.error("Error fetching progress reports:", error);
      }
    };
    if (progressReportsToggle) {
      fetchProgressReports();
    }
  }, [progressReportsToggle]);

  return (
    <div>
      <div className="search-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div class="night">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>

        <div className="search-fields" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "50px", width: "100%" }}>
          <TextField
            sx={{
              backgroundColor: "#4484FF",
              borderRadius: "10px",
              '& .MuiInputLabel-root': {
                color: '#ffad3d',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffad3d',
                },
                '&:hover fieldset': {
                  borderColor: '#ffad3d',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ffad3d',
                },
                '& input': {
                  color: '#ffad3d',
                },
              },
            }}
            onChange={handleSearchByName}
            className="search-input"
            label="Search By Name"
            variant="outlined"
          />

          <TextField
            label="Search By Date"
            type="date"
            sx={{
              backgroundColor: "#4484FF",
              borderRadius: "10px",
              '& .MuiInputLabel-root': {
                color: '#ffad3d',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ffad3d',
                },
                '&:hover fieldset': {
                  borderColor: '#ffad3d',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ffad3d',
                },
                '& input': {
                  color: '#ffad3d',
                },
              },
            }}
            InputLabelProps={{ shrink: true }}
            value={searchDate ? dayjs(searchDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => handleSearchByDate(dayjs(e.target.value))}
          />
        </div>
      </div>
      <TableContainer component={Paper} className="leaveContainer">

        <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
          PROGRESS REPORTS
        </h4>
        <Table sx={{ minWidth: 650 }} className="leaveTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Name</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Email</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Position</span>
              </TableCell>
              <TableCell className="myTableHeadCells">
                <span className="myTableHeadSpan">Date</span>
              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                <span className="myTableHeadSpan">Report</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReports.length > 0 ? (
              filteredReports.map((report) => (
                <TableRow key={report._id}>
                  <TableCell sx={{ fontSize: "large" }}>{report.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{report.email}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{"Junior Dev"}</TableCell>
                  <TableCell sx={{ fontSize: "large", height: "50px" }}>
                    {new Date(report.date).toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: "large" }}>
                    <Button
                      onClick={() =>
                        handleOpenDialog({
                          name: report.name,
                          email: report.email,
                          date: report.date,
                          reportDetail: report.reportDetail,
                        })
                      }
                    >
                      View Report
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              reportList.map((report) => (
                <TableRow key={report._id}>
                  <TableCell sx={{ fontSize: "large" }}>{report.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{report.email}</TableCell>
                  <TableCell sx={{ fontSize: "large" }}>{"Junior Dev"}</TableCell>
                  <TableCell sx={{ fontSize: "large", height: "50px" }}>
                    {new Date(report.date).toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: "large" }}>
                    <Button
                      onClick={() =>
                        handleOpenDialog({
                          name: report.name,
                          email: report.email,
                          date: report.date,
                          reportDetail: report.reportDetail,
                        })
                      }
                    >
                      View Report
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogActions
          sx={{ position: "absolute", right: 0, padding: "15px 5px" }}
        >
          <Button onClick={handleCloseDialog}>
            <CloseIcon />
          </Button>
        </DialogActions>

        <div className="dialogTop">
          <div className="dialogLeft">
            <DialogTitle className="dialogHeading">Name</DialogTitle>
            <p className="dialogtext">{dialogContent.name}</p>
            <DialogTitle className="dialogHeading">Email</DialogTitle>
            <p className="dialogtext">{dialogContent.email}</p>
            <DialogTitle className="dialogHeading">Date</DialogTitle>
            <p className="dialogtext">
              {new Date(dialogContent.date).toLocaleString()}
            </p>
          </div>
          <div className="dialogRight">
            <img src={logo} width="220" height="220" alt="GROWTH GUILD" />
          </div>
        </div>
        <DialogTitle className="dialogHeading">Report</DialogTitle>
        <DialogContent className="dialogtextReport">
          {dialogContent.reportDetail}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProgressRep;
