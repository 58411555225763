// AddEmployee.js
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import GreenLogo from "../../../utilities/hr-dashboard-green-icon.svg";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./Dashboard.css";

const Dashboard = (props) => {
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [activeEmployees, setActiveEmployees] = useState(0);
  const [employees, setEmployees] = useState([]);

  const fetchAllTimers = async () => {
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/timers'); // Replace with your backend URL
      const data = await response.json();
      // console.log(data);
      setActiveEmployees(data.timers.length);
      setEmployees(data.timers);
    } catch (error) {
      console.error('Error fetching timers:', error);
    }
  };

  // Poll the backend for all timers every 10 seconds
  useEffect(() => {
    fetchAllTimers(); // Initial fetch

    const intervalId = setInterval(fetchAllTimers, 4000); // Poll every 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchTotalEmployees = async () => {
      try {
        const response = await fetch('https://hr-backend-72v1.onrender.com/totalEmployees'); // Backend URL for total employees
        const data = await response.json();
        setTotalEmployees(data.totalEmployees);
      } catch (error) {
        console.error('Error fetching total employees:', error);
      }
    };

    fetchTotalEmployees();
  }, []);

  const data = [
    { label: 'Total Employees', count: totalEmployees },
    { label: 'Active Employees', count: activeEmployees }
  ];

  const formatTime = (timeInSeconds) => {
    const hrs = Math.floor(timeInSeconds / 3600);
    const mins = Math.floor((timeInSeconds % 3600) / 60);
    const secs = timeInSeconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };


  return (
    <>
      <div className="dashboardFront">
        {data.map((item, index) => (
          <Card variant="outlined" className="card" key={index}>
            <Card.Header className="cardHeader">
              <span>{item.label}</span>
              <span>
                <img src={GreenLogo} alt="Green Logo" />
              </span>
            </Card.Header>
            <Card.Body className="cardBody">
              <span>{item.count}</span>
              <span>Employees</span>
            </Card.Body>
          </Card>
        ))}
      </div>



      <div className="dashboardBottom">
        <TableContainer component={Paper} className="dashboardTimerTableContainer" variant="outlined">
          <h4 className="myTableHeader">EMPLOYEE STATUS</h4>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="dashboardTableRow">
                <TableCell className="myTableHeadCells">
                  <span className="myTableHeadSpan">Name</span>
                </TableCell>
                <TableCell className="myTableHeadCells" align="left">
                  <span className="myTableHeadSpan">Email</span>
                </TableCell>
                <TableCell className="myTableHeadCells" align="left">
                  <span className="myTableHeadSpan">Timer</span>
                </TableCell>
                <TableCell className="myTableHeadCells" align="right">
                  <span className="myTableHeadSpan">Status</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((emp) => (
                <TableRow className="dashboardTableRow" key={emp.email}>
                  <TableCell sx={{ fontSize: "large" }}>{emp.name}</TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {emp.email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {formatTime(emp.time)}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="right">
                    <span className={emp.isRunning ? "active" : "paused"}>
                      {(emp.isRunning && !emp.isPaused) ? "Running" : (emp.isRunning && emp.isPaused) ? "Paused" : "Stopped"}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Dashboard;
