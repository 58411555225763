import React, { useState, useEffect, useRef } from "react";
// import PersonalInfo from './Personal Info/PersonalInfo';
// import SalaryInfo from './Salary Info/SalaryInfo';
// import Documents from './documents Info/Documents';
// import GoBack from '../../../utilities/goback-png.png';
import CheckIcon from '@mui/icons-material/Check';

import {
    Button,
    Autocomplete,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';


import "./managerInfo.css";




const ManagerInfo = (props) => {
    const user = props.user;
    const Employee = props.Employee;

    const [leademployees, setleadEmployees] = useState([]);
    const [selectedleadEmployee, setSelectedleadEmployee] = useState(null);
    const [projectData, setProjectData] = useState([]);


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await fetch('https://hr-backend-72v1.onrender.com/employees-without-manager');
                const data = await response.json();
                setleadEmployees(data);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchEmployees();
    }, []);

    const assignEmployeeToManager = async () => {

        const isValidEmployee = leademployees.some(
            (employee) => employee.email === selectedleadEmployee?.email
        );

        if (!isValidEmployee) {
            alert("Please enter a valid option from the list.");
            return;
        }
        if (!selectedleadEmployee) {
            alert('Please select an employee');
            return;
        }

        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/assign-employee', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    managerEmail: Employee.email,
                    employeeName: selectedleadEmployee.name,
                    employeeEmail: selectedleadEmployee.email
                }),
            });

            if (response.ok) {
                alert('Employee assigned to manager successfully');
            } else {
                alert('Failed to assign employee');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    const promoteToManager = async (email, name) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/makemanager', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name }),
            });

            if (response.ok) {
                console.log('Employee promoted to Manager');
                alert('Employee promoted to Manager');
            } else {
                alert('Failed to promote Employee');
                console.log('Failed to promote Employee');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchProjectData = async () => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/getmanagerprojectdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: Employee.email,
                }),
            });

            const data = await response.json();
            console.log(data);
            setProjectData(data);
        } catch (error) {
            console.error('Error fetching project data:', error);
        }
    };

    useEffect(() => {
        fetchProjectData();
    }, [Employee]);

    const approveManager = async (projectId) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/approve-report-manager', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId }),
            });

            if (response.ok) {
                const updatedProject = await response.json();
                setProjectData(prevData =>
                    prevData.map(project =>
                        project._id === projectId
                            ? { ...project, approvedbymanager: true }
                            : project
                    )
                );
                alert('Project approved by manager');
            } else {
                alert('Failed to approve project detail');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const approveHR = async (projectId) => {
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/approve-report-hr', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectId }),
            });

            if (response.ok) {
                const updatedProject = await response.json();
                setProjectData(prevData =>
                    prevData.map(project =>
                        project._id === projectId
                            ? { ...project, approvedbyhr: true }
                            : project
                    )
                );
                alert('Project approved by HR');
            } else {
                alert('Failed to approve project detail');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="manager-container">
             <h2 className="heading-xx-large">Manager Tab</h2>
             <hr />
            <div style={{ width: "90%", margin: "auto" }}>
            {(Employee.manager == 'no' && user.role == 'HR') &&  
                    <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "30px" }}>
                        <h1 className="heading">Employee is Not A Manager Right Now !</h1>
                        <Button
                            variant="outlined"
                            onClick={() => promoteToManager(Employee.email, Employee.name)}
                        >
                            Promote to Manager
                        </Button>
                    </div>
                }
                {Employee.manager == 'yes' &&
                    <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <Autocomplete style={{ width: "30%" }}
                                options={leademployees}
                                getOptionLabel={(option) => `${option.name} (${option.email})`}
                                renderInput={(params) => <TextField {...params} label="Assign New Employee" variant="standard" />}
                                onChange={(event, newValue) => setSelectedleadEmployee(newValue)}
                            />
                            <Button style={{ borderRadius: "50%" }} variant="contained" onClick={assignEmployeeToManager}>Assign</Button>
                        </div>
                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                            {projectData && projectData.length > 0 && (
                                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="myTableHeadCells">Name</TableCell>
                                                <TableCell className="myTableHeadCells">Appointments</TableCell>
                                                <TableCell className="myTableHeadCells">Email</TableCell>
                                                <TableCell className="myTableHeadCells">Pipelines</TableCell>
                                                <TableCell className="myTableHeadCells">Date</TableCell>
                                                <TableCell className="myTableHeadCells">HR Approval</TableCell>
                                                <TableCell className="myTableHeadCells">Manager Approval</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projectData.map((project) => (
                                                <TableRow key={project._id}>
                                                    <TableCell>{project.name}</TableCell>
                                                    <TableCell>{project.appointmentsTaken}</TableCell>
                                                    <TableCell>{project.email}</TableCell>
                                                    <TableCell
                                                        sx={{ color: project.pipelineMoved === 'yes' ? '#4484FF' : 'red' }}
                                                    >
                                                        {project.pipelineMoved}
                                                    </TableCell>
                                                    <TableCell>{new Date(project.date).toLocaleDateString()}</TableCell>
                                                    <TableCell>{project.approvedbyhr ? 'Yes' :
                                                        <>
                                                            {user.role === 'HR' && project.approvedbymanager ? (
                                                                <IconButton onClick={() => approveHR(project._id)}>
                                                                    <CheckIcon />
                                                                </IconButton>
                                                            ) : (
                                                                user.role === 'HR' && !project.approvedbymanager ? 'No' : null
                                                            )}

                                                        </>
                                                    }
                                                    </TableCell>
                                                    <TableCell>{project.approvedbymanager ? 'Yes' :
                                                        <>
                                                            {user.role !== 'HR' ? (
                                                                <IconButton onClick={() => approveManager(project._id)}>
                                                                    <CheckIcon />
                                                                </IconButton>) :
                                                                ('No')
                                                            }

                                                        </>
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </div>
                    </>
                }
            </div>
        </div>

    );
};

export default ManagerInfo;
