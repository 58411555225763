import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import GreenLogo from "../../../utilities/hr-dashboard-green-icon.svg";

import {
  Button,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import dayjs from 'dayjs';

import "./RequestLeave.css";

const RequestLeave = ({ user, Employee }) => {
  const [creditedAnnualLeave, setCreditedAnnualLeave] = useState(0);
  const [remainingAnnualLeaves, setRemainingAnnualLeaves] = useState(0);
  const [creditedSickLeave, setCreditedSickLeave] = useState(100);
  const [remainingSickLeaves, setRemainingSickLeaves] = useState(100);
  const [creditedPersonalDays, setCreditedPersonalDays] = useState(0);
  const [remainingPersonalDays, setRemainingPersonalDays] = useState(0);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [leaveReason, setLeaveReason] = useState('');
  const [error, setError] = useState('');
  const [leaveData, setLeaveData] = useState([]);
  const [activeLeaveType, setActiveLeaveType] = useState('Annual Leave');

  const [selectedType, setSelectedType] = useState('Annual Leave');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const filteredLeaves = leaveData.filter(leave => leave.leaveType === selectedType);


  const leaveCredits = {
    "Annual Leave": { credited: creditedAnnualLeave, remaining: remainingAnnualLeaves },
    "Sick Leave": { credited: creditedSickLeave, remaining: remainingSickLeaves },
    "Personal Days Off": { credited: creditedPersonalDays, remaining: remainingPersonalDays },
  };


  const handleToggle = (type) => {
    setActiveLeaveType(type);
    fetchLeaveData(type);
    setSelectedType(type);
  };

  const validateDates = () => {
    if (!fromDate || !toDate) {
      setError("Both From Date and To Date are required.");
      return false;
    }
    if (dayjs(fromDate).isAfter(dayjs(toDate))) {
      setError("From date must be earlier than To date.");
      return false;
    }
    setError("");
    return true;
  };
  
  const handleSubmit = async () => {
    if (!validateDates()) return;
  
    // Calculate requested days
    const requestedDays = dayjs(toDate).diff(dayjs(fromDate), 'day') + 1;
    const remainingDays = leaveCredits[selectedType].remaining;
  
    // Check if requested days exceed the remaining days
    if (requestedDays > remainingDays) {
      alert(`You can only request up to ${remainingDays} days for ${selectedType}.`);
      return;
    }
  
    const requestBody = {
      email: Employee.email,
      name: Employee.name,
      fromDate: dayjs(fromDate).toISOString(),
      toDate: dayjs(toDate).toISOString(),
      reason: leaveReason,
      leaveType: selectedType,
    };
  
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/applyleave', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        alert("Leave request submitted successfully!");
        fetchLeaveData(selectedType); // Refresh leave data on successful submission
      } else {
        const errorData = await response.json();
        alert(errorData.error || "Failed to submit leave request.");
      }
    } catch (error) {
      console.error("Error submitting leave request:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  
  const handleFromDateChange = (e) => {
    const value = e.target.value;
    if (value) {
      setFromDate(dayjs(value).format('YYYY-MM-DD'));
    } else {
      setFromDate(null);
    }
  };
  
  const handleToDateChange = (e) => {
    const value = e.target.value;
    if (value) {
      setToDate(dayjs(value).format('YYYY-MM-DD'));
    } else {
      setToDate(null);
    }
  };
  

  const handleReasonChange = (e) => {
    setLeaveReason(e.target.value);
  };

  const fetchLeaveData = async (leaveType = 'Annual Leave') => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/leaves?email=${Employee.email}`);
      if (response.ok) {
        const data = await response.json();
        setLeaveData(data.leaves);  // Set the detailed leave entries
        // Update the state based on the returned summary data
        if (data.leaveSummary) {
          setCreditedAnnualLeave(data.leaveSummary.annualLeavesCredited);
          setRemainingAnnualLeaves(data.leaveSummary.annualLeavesRemaining);
          setCreditedSickLeave(data.leaveSummary.sickLeavesCredited || 100);
          setRemainingSickLeaves(data.leaveSummary.sickLeavesRemaining || 100);
          setCreditedPersonalDays(data.leaveSummary.personalDaysCredited);
          setRemainingPersonalDays(data.leaveSummary.personalDaysRemaining);
        }
      } else {
        console.error("Failed to fetch leave data");
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };


  useEffect(() => {
    if (Employee.email) {
      fetchLeaveData();
    }
  }, [Employee.email]);

  // const sortedLeaveData = leaveData.sort((a, b) => {
  //   const order = ['Pending', 'Approved', 'Rejected'];
  //   return order.indexOf(a.status) - order.indexOf(b.status);
  // });

  const data = [
    { label: `${activeLeaveType} Credited`, count: leaveCredits[activeLeaveType].credited },
    { label: `${activeLeaveType} Remaining`, count: leaveCredits[activeLeaveType].remaining },
  ];

  const leaveTypes = {
    'Annual Leave': { credited: creditedAnnualLeave, remaining: remainingAnnualLeaves },
    'Sick Leave': { credited: creditedSickLeave, remaining: remainingSickLeaves },
    'Personal Days Off': { credited: creditedPersonalDays, remaining: remainingPersonalDays },
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted': return 'green';
      case 'Rejected': return 'red';
      case 'Pending': return 'blue';
      default: return 'black';
    }
  };

  return (
    <div className="leaves-container">
      <div className="toggle-leave-buttons" style={{ width: "50%", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
        <Button onClick={() => handleToggle('Annual Leave')} variant={activeLeaveType === 'Annual Leave' ? "contained" : "outlined"}>Annual Leave</Button>
        <Button onClick={() => handleToggle('Sick Leave')} variant={activeLeaveType === 'Sick Leave' ? "contained" : "outlined"}>Sick Leave</Button>
        <Button onClick={() => handleToggle('Personal Days Off')} variant={activeLeaveType === 'Personal Days Off' ? "contained" : "outlined"}>Personal Days Off</Button>
      </div>
      <div className="leaves-header">
        {selectedType == 'Annual Leave' &&
          <p className="leaves-header-heading">A N N U A L<span className="leaves-space"></span>L E A V E S</p>
        }
        {selectedType == 'Sick Leave' &&
          <p className="leaves-header-heading">S I C K<span className="leaves-space"></span>L E A V E S</p>
        }
        {selectedType == 'Personal Days Off' &&
          <p className="leaves-header-heading">P E R S O N A L<span className="leaves-space"></span>D A Y S<span className="leaves-space"></span>L E A V E S</p>
        }
        {activeLeaveType == 'Sick Leave' ? (
          <div style={{ display: "none" }} >
          </div>
        ) : (
          <div className="requestLeaveFront">
            {data.map((item, index) => (
              <Card variant="outlined" className="card" key={index}>
                <Card.Header className="cardHeader">
                  <span>{item.label}</span>
                  <span>
                    <img src={GreenLogo} alt="Green Logo" />
                  </span>
                </Card.Header>
                <Card.Body className="cardBody">
                  <span>{item.count}</span>
                  <span>Leaves</span>
                </Card.Body>
              </Card>
            ))}
          </div>
        )

        }

        <h4 className="Request-leave-headings">Request Leave</h4>
        <div className="input-from-to" style={{ display: 'flex', gap: '30px' }}>
          <TextField
            sx={{ margin: "0px", padding: "0px" }}
            label="From Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : ''}
            onChange={handleFromDateChange}
          />
          <TextField
            label="To Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={toDate ? dayjs(toDate).format('YYYY-MM-DD') : ''}
            onChange={handleToDateChange}
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="leave-reason" style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <TextField
            label="Reason for Leave"
            multiline
            rows={4}
            value={leaveReason}
            onChange={handleReasonChange}
            fullWidth
            variant="outlined"
          />
          <Button style={{ marginTop: "20px", width: "30%" }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </div>
        <div className="leave-status">
          <h4 className="Request-leave-headings">Leave Status</h4>
          <TableContainer component={Paper} >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells" sx={{ width: '33%' }}>Total Days</TableCell>
                  <TableCell className="myTableHeadCells" sx={{ width: '33%' }}>Status</TableCell>
                  <TableCell className="myTableHeadCells" sx={{ width: '33%' }}>Date Range</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLeaves.map((leave, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: '33%' }}>
                      {dayjs(leave.toDate).diff(dayjs(leave.fromDate), 'day') + 1}
                    </TableCell>
                    {leave.hrstatus == 'Rejected' || leave.executivestatus == 'Rejected' ? (
                      <TableCell sx={{ width: '33%', color: getStatusColor(leave.hrstatus) }}>
                        Rejected
                      </TableCell>
                    ) : (
                      <TableCell sx={{ width: '33%', color: getStatusColor(leave.executivestatus) }}>
                        {leave.executivestatus}
                      </TableCell>
                    )}
                    <TableCell sx={{ width: '33%' }}>
                      {dayjs(leave.fromDate).format('YYYY-MM-DD')} to {dayjs(leave.toDate).format('YYYY-MM-DD')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div >
  );
};

export default RequestLeave;
