import React, { useState, useEffect } from "react";
import "./Mastermind.css";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  InputAdornment,
  Alert
} from "@mui/material";
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import MasterMind from "../../../utilities/mastermind.png";
import logo from "../../../utilities/logo-png.png";
import NewRetainer from "./NewRetainer";

const Mastermind = (props) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [message, setMessage] = useState(null);
  const [openRetainerAdd, setopenRetainerAdd] = useState(false);
  const [openRetainerEmployeeAdd, setopenRetainerEmployeeAdd] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [openRetainer, setOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // Effect to filter data based on search term
  useEffect(() => {
    if (searchTerm) {
      const results = props.masterMindData.filter(report =>
        report.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(results);
    } else {
      setFilteredData(props.masterMindData);
    }
  }, [searchTerm, props.masterMindData]);

  useEffect(() => {

    // Show the message if it's present, and auto-hide after 5 seconds
    if (message) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Auto-hide after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message]);



  // const getUniqueClientCount = (data) => {
  //   if (data !== null && data.length > 0) {
  //     const clientNames = new Set();
  //     data.forEach(item => clientNames.add(item.clientName));
  //     return clientNames.size;
  //   }
  //   return 0; // Return 0 if data is null or an empty array
  // };

  // // Usage in your component's return statement or render method
  // const uniqueClientCount = getUniqueClientCount(props.retainersData);



  const handleOpenRetainerAdd = () => {
    setopenRetainerAdd(true);
  };

  const handleCloseRetainerAdd = () => {
    setopenRetainerAdd(false);
  };
  const handleOpenRetainerEmployeeAdd = () => {
    setopenRetainerEmployeeAdd(true);
  };

  const handleCloseRetainerEmployeeAdd = () => {
    setopenRetainerEmployeeAdd(false);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch('https://hr-backend-72v1.onrender.com/employees/emails');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data);
        setEmails(data);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };
    fetchEmails();
  }, []);



  const handleSendEmployee = async () => {
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/api/project/addemployee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projectName: props.heading, // Assuming 'heading' is the project name
          employee: {
            name: selectedEmail.name,
            email: selectedEmail.email,
            startingDate: new Date(), // Sets today's date
          }
        }),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Employee added:', result);
        setMessage("Employee added successfully");
        setMessageType('success');

      } else {
        setMessage("Error Adding Employee");
        setMessageType('error');
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Failed to add employee:', error.message);
      setMessage("Error Adding Employee");
      setMessageType('error');

    }

  }

  const calculateTenure = (joiningDate) => {
    const now = new Date();
    const joinDate = new Date(joiningDate);
    const years = now.getFullYear() - joinDate.getFullYear();
    const months = now.getMonth() - joinDate.getMonth() + years * 12;
    const days =
      now.getDate() >= joinDate.getDate()
        ? now.getDate() - joinDate.getDate()
        : new Date(joinDate.getFullYear(), joinDate.getMonth() + 1, 0).getDate() -
        joinDate.getDate() +
        now.getDate();

    const fullMonths = Math.floor(months + days / 30);
    const fullDays = Math.floor(days % 30);

    return `${fullMonths} month${fullMonths !== 1 ? "s" : ""} ${fullDays} day${fullDays !== 1 ? "s" : ""
      }`;
  };

  return (
    <div>
      {showMessage &&
        <div
          className="animate__animated animate__slideInDown"
          style={{
            position: 'fixed',
            top: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <Alert severity={messageType} style={{ marginBottom: '20px' }}>
            {message}
          </Alert>
        </div>
      }

      <div className="masterTop">
        <Button
          className="backButton"
          sx={{ border: "2px solid" , marginLeft: "5%" }}
          onClick={props.onBack}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </Button>


        {/* <div>
          {props.image === "MasterMind" && (
            <TextField
              className="masterInput"
              label="Add Employee"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonAddAltIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div> */}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <h4 className="myTableHeader masterHeader animate__animated animate__lightSpeedInLeft">
          {props.heading}
        </h4>
        <div style={{ display: "flex", justifyContent: "space-around", width: "65%" }}>

          {props.image === "ProjectRetainers" ? (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", alignItems: "center" }}>
              <Button variant="contained" onClick={handleOpenRetainerEmployeeAdd} style={{ textAlign: "center", alignContent: "center", height: "max-content" }}>
                Add New Employee
              </Button>
              <Button variant="contained" onClick={handleOpenRetainerAdd} style={{ textAlign: "center", alignContent: "center", height: "max-content" }}>
                Create New Retainer
              </Button>
            </div>
          ) : props.image === "MasterMind" ? null : (
            <>
              <Autocomplete
                sx={{ width: '50%', margin: '0px' }}
                options={emails}
                getOptionLabel={(option) => option ? `${option.name || ''} - ${option.email || ''}` : ''}
                value={selectedEmail}
                onChange={(event, newValue) => {
                  setSelectedEmail(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Employee"
                    variant="outlined"
                  />
                )}
              />
              {selectedEmail && (
                <Button onClick={handleSendEmployee}>Add Employee</Button>
              )}
            </>
          )}


        </div>
      </div>

      <div className="masterMid">

        <Table sx={{ minWidth: 250 }} className="masterTable">
          <TableHead>
            <TableRow>
              <TableCell className="myTableHeadCells">
                {props.image === "ProjectRetainers" ?
                  (
                    <span className="myTableHeadSpan">Total Retainers</span>
                  ) : (
                    <span className="myTableHeadSpan">Starting Time</span>
                  )}

              </TableCell>
              <TableCell className="myTableHeadCells" align="right">
                {props.image === "ProjectRetainers" ?
                  (
                    <span className="myTableHeadSpan">Total Employees Associated</span>
                  ) : (
                    <span className="myTableHeadSpan">Total Employees</span>
                  )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: "large" }}>
                {props.masterMindData && props.masterMindData.startingDate}
                {/* {props.retainersData && uniqueClientCount} */}
                {props.project && props.project.startingDate.split('T')[0]}
              </TableCell>
              <TableCell
                sx={{ fontSize: "large", paddingRight: "100px" }}
                align="right"
              >
                {props.masterMindData && props.masterMindData.length}
                {props.retainersData && props.retainersData.length}
                {props.project && props.project.employees && props.project.employees.length}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className="masterImage">
          <img
            src={props.image === "MasterMind" ? MasterMind : logo}
            width="30%"
          />
        </div>
      </div>

      {props.image === "MasterMind" && (
        <div className="masterSearch">
          <h2 style={{fontSize: "x-large" , color: "gray" , margin: '10px 10px' , fontWeight: "700"}}>Search</h2>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "80%", margin: '10px 10px' }}
          />
        </div>
      )}

      <TableContainer
        component={Paper}
        className="leaveContainer"
        style={{ width: "100% !important" }}
      >
        <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft">
          Employees
        </h4>
        <Table sx={{ minWidth: 650 }} className="leaveTable">
          {props.image === "MasterMind" && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Name</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Appointments</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Email</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Pipelines</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Date</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells">
                    <span className="myTableHeadSpan">Team Lead</span>
                  </TableCell>
                  {/* <TableCell className="myTableHeadCells" align="right">
                    <span className="myTableHeadSpan">Status</span>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredData.length > 0 ? filteredData  : (props.masterMindData)).map((report) => {
                  return (
                    <TableRow key={report._id}>
                      <TableCell sx={{ fontSize: "large" }}>
                        {report.name}
                      </TableCell>
                      <TableCell sx={{ fontSize: "large" }}>
                        {report.appointmentsTaken}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "large",
                          fontWeight: "bold",
                          color: report.emailSent === "yes" ? "#4484FF" : "red",
                        }}
                      >
                        {report.emailSent}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "large",
                          fontWeight: "bold",
                          color:
                            report.pipelineMoved === "yes" ? "#4484FF" : "red",
                        }}
                      >
                        {report.pipelineMoved}
                      </TableCell>

                      <TableCell sx={{ fontSize: "large" }}>
                        {new Date(report.date).toLocaleDateString({
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell sx={{ fontSize: "large" }}>{report.teamLead}</TableCell>
                      {/* <TableCell sx={{ fontSize: "large" }} align="right">
                        <span
                          className={
                            report.name === "Khaliq"
                              ? "active"
                              : report.name === "On-Leave"
                                ? "onLeave"
                                : "inActive"
                          }
                        >
                          demo
                        </span>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          )}
          {props.image === "ProjectRetainers" && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="myTableHeadCells">Name</TableCell>
                    <TableCell className="myTableHeadCells">Joining Date</TableCell>
                    <TableCell className="myTableHeadCells">Tenure</TableCell>
                    <TableCell className="myTableHeadCells">Client Name</TableCell>
                    {/* <TableCell className="myTableHeadCells" align="right">
                      Status
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.retainersData &&
                    props.retainersData.map((report, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "large" }}>{report.name}</TableCell>
                        <TableCell sx={{ fontSize: "large" }}>
                          {new Date(report.joiningDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell sx={{ fontSize: "large" }}>
                          {calculateTenure(report.joiningDate)}
                        </TableCell>
                        <TableCell sx={{ fontSize: "large" }}>
                          {report.clientName}
                        </TableCell>
                        {/* <TableCell sx={{ fontSize: "large" }} align="right">
                          <span className={report.status.toLowerCase()}>
                            {report.status}
                          </span>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </>
          )}
          {props.projectsToggle == 'showProject' && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell className="myTableHeadCells" style={{ textAlign: "left" }}>
                    <span className="myTableHeadSpan">Name</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells" style={{ textAlign: "left" }}>
                    <span className="myTableHeadSpan">Email</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells" style={{ textAlign: "right" }}>
                    <span className="myTableHeadSpan">Start Date</span>
                  </TableCell>
                  <TableCell className="myTableHeadCells" style={{ textAlign: "right" }}>
                    <span className="myTableHeadSpan">Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.project && props.project.employees && props.project.employees.length > 0 && props.project.employees.map((employee) => (
                  <TableRow key={employee._id}>
                    <TableCell sx={{ fontSize: "large", textAlign: "left" }}>
                      {employee.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large", textAlign: "left" }}>
                      {employee.email}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large", textAlign: "right" }}>
                      {employee.startingDate.split('T')[0]}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large", textAlign: "right" }}>
                      <span className="active">
                        {"active"}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </>
          )

          }
        </Table>
      </TableContainer>
      <div>
        <NewRetainer open={openRetainerAdd}
          openRetainerEmployeeAdd={openRetainerEmployeeAdd}
          handleOpenRetainerAdd={handleOpenRetainerAdd}
          handleCloseRetainerAdd={handleCloseRetainerAdd}
          handleOpenRetainerEmployeeAdd={handleOpenRetainerEmployeeAdd}
          handleCloseRetainerEmployeeAdd={handleCloseRetainerEmployeeAdd}
          emails={emails} />
      </div>
    </div>
  );
};

export default Mastermind;
