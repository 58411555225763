import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    // DialogActions,
    // DialogContent,
    // TableContainer,
    TextField,
    // Box,
    // InputLabel,
    // MenuItem,
    // FormControl,
    // Select,
    // Table,
    // TableBody,
    // TableCell,
    // TableHead,
    // TableRow,
    // Paper,
    // Dialog,

} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GooeyButton from '../../../../utilities/GooeyButton';
import Autocomplete from '@mui/material/Autocomplete';



import "./salaryInfo.css";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { TroubleshootOutlined } from "@mui/icons-material";

const SalaryInfo = (props) => {
    const user = props.user ? props.user : null;
    const Employee = props.Employee;
    const [disableSend, setdisableSend] = useState(false);
    const [toggleEditSalary, settoggleEditSalary] = useState(false);
    const [salary, setSalary] = useState(Employee ? Employee.salary : "");
    const [openProjInc, setopenProjInc] = useState(false);
    const [projectInc, setProjectInc] = useState([]);
    const [retainerInc, setRetainerInc] = useState([]);


    const [projectIncData, setprojectIncData] = useState({
        increments: "",
        projectname: "",
        incDate: "",
    });
    const [RelaventProjects, setRelaventProjects] = useState([]);

    const incrementData = [
        ['50000 PKR', '15-08-2020'],
        ['50000 PKR', '15-08-2020'],
        ['50000 PKR', '15-08-2020'],
        ['50000 PKR', '15-08-2020'],
        ['50000 PKR', '15-08-2020'],
        ['70000 PKR', '15-08-2021']
    ];



    // const projectInc = [
    //     ['5000 PKR', 'Project A', '15-08-2020'],
    //     ['500 PKR', 'Project B', '15-08-2020'],
    //     ['50 PKR', 'Project A', '15-08-2020'],
    //     ['500 PKR', 'Project B', '15-08-2020'],
    //     ['5000 PKR', 'Project A', '15-08-2020'],
    //     ['7000 PKR', 'Project B', '15-08-2021']
    // ];
    // const retainerInc = [
    //     ['5000 PKR', 'Garry', '15-08-2020'],
    //     ['500 PKR', 'Matthew', '15-08-2020'],
    //     ['50 PKR', 'Garry', '15-08-2020'],
    //     ['500 PKR', 'Matthew', '15-08-2020'],
    //     ['5000 PKR', 'Garry', '15-08-2020'],
    //     ['7000 PKR', 'Matthew', '15-08-2021']
    // ];

    const extraAllowance = [
        ['5000 PKR', 'Medical'],
        ['6000 PKR', 'Internet'],
        ['7000 PKR', 'Phone'],
        ['8000 PKR', 'Travel'],
        ['9000 PKR', 'Yearly Bonus']
    ];

    const inputEditHandler = () => {
        settoggleEditSalary(false);
    };

    const handleProjectIncData = (e) => {
        const { name, value } = e.target;
        console.log("e.target ", e.target);
        setprojectIncData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const SendProjectIncData = async () => {
        setdisableSend(true);
        try {
            const response = await fetch('https://hr-backend-72v1.onrender.com/addprojectincrement', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: Employee.email,
                    name: Employee.name,
                    projectName: projectIncData.projectname,
                    increment: parseInt(projectIncData.increments, 10)
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setdisableSend(false);
                console.log('Increment data sent successfully:', data);
                alert('Data Uploaded Succesfully');
                fetchData();
            } else {
                setdisableSend(false);
                throw new Error(data.message);
                alert('Could not add Data');
            }
        } catch (error) {
            setdisableSend(false);
            alert('Could not add Data');
            console.error('Failed to send increment data:', error.message);
        }
    };

    useEffect(() => {
        const fetchRetainerIncrements = async () => {
            try {
                const response = await fetch(`https://hr-backend-72v1.onrender.com/retainer-increments?email=${Employee.email}`);
                const data = await response.json();
                setRetainerInc(data);
            } catch (error) {
                console.error('Error fetching retainer increments:', error);
            }
        };

        if (Employee) {
            fetchRetainerIncrements();
        }
    }, [Employee]);


    useEffect(() => {
        if (user.role == 'HR' || user.role == 'Executive') {
            const fetchRelaventProjects = async () => {
                try {
                    const response = await fetch(`https://hr-backend-72v1.onrender.com/api/relaventprojects/${Employee.email}`);
                    if (!response.ok) throw new Error('Failed to fetch projects');
                    const data = await response.json();
                    setRelaventProjects(data);
                } catch (error) {
                    console.error(error.message);
                    setRelaventProjects([]);
                }
            };
            fetchRelaventProjects();
        }
    }, [user]);

    const fetchData = async () => {
        try {
            const response = await fetch(`https://hr-backend-72v1.onrender.com/getrelaventincrements?email=${Employee.email}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setProjectInc(data.map(inc => [inc.increment, inc.projectName, inc.incrementDate])); // Update projectInc with latest data
            setopenProjInc(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (Employee) {
            fetchData();
        }
    }, [Employee]);

    return (
        <div>
            <div className="salary-container">
                <div className="Salary-head">
                    <h2 className="heading-xx-large">Salary</h2>
                    <hr />
                    <div className="total-sal">

                        <p className="heading-large">Total Salary</p>
                        <div className="sal-input">
                            {!toggleEditSalary ? (
                                <input
                                    className="input-like-sal-info"
                                    type="text"
                                    value={salary}
                                    readOnly
                                />
                            ) : (
                                <input
                                    className="input-like-sal-info"
                                    type="text"
                                    placeholder="Enter Name here"
                                    maxLength="50"
                                    value={salary}
                                    onChange={(e) => setSalary(e.target.value)}
                                />
                            )}
                            {(user.role == 'HR' || user.role == 'Executive') && (
                                !toggleEditSalary ? (
                                    <Button onClick={() => settoggleEditSalary(true)}>
                                        <EditOutlinedIcon />
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            props.inputEditHandler(Employee.email, "salary", salary, settoggleEditSalary)
                                        }
                                    >
                                        <DoneIcon style={{ padding: "0px" }} />
                                    </Button>
                                )
                            )}

                        </div>
                    </div>
                    {/* <div className="total-tax">
                        <p className="heading-large">Tax</p>
                        <div className="sal-input">
                            <input className="input-like-sal-info" type="text" value={Employee ? Employee.tax : ""} readOnly />
                        </div>
                    </div> */}
                    {/* <div className="uptodate-salary">
                        <div class="grid-container-salary">
                            <div className="grid-item-salary grid-item-salary-head"><strong>Salaries Upto Date</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increment Date</strong></div>
                            {incrementData.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="grid-item-salary">{item[0]}</div>
                                    <div className="grid-item-salary">{item[1]}</div>
                                </React.Fragment>
                            ))}
                        </div>
                        {user.role == 'HR' &&
                            <div className="add-sal-btn" style={{ textAlign: "center", margin: "20px" }}>
                                <GooeyButton> <AddCircleOutlineOutlinedIcon className="add-circle-btn" sx={{ color: "#06c8d9", height: "30px", width: '30px' }} /> Add Salary Increment</GooeyButton>
                            </div>
                        }
                    </div> */}
                    <div className="projects-increments">
                        <div className="heading-large" style={{ textAlign: 'center', width: '30%', padding: '25px', margin: 'auto' }}>Projects Increments<hr /></div>
                        <div className="grid-container-projects-increments">
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increments</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Project Name</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increment Date</strong></div>
                            {Array.isArray(projectInc) && projectInc.length > 0 ? (
                                projectInc.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <div className="grid-item-salary">{item[0]}</div>
                                        <div className="grid-item-salary">{item[1]}</div>
                                        <div className="grid-item-salary">{item[2].split('T')[0]}</div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <p style={{textAlign: "center" , alignContent: "center" , margin: "10px"}}>No project increments yet.</p>
                            )}
                            {openProjInc && (
                                <>
                                    <input
                                        className="grid-item-salary"
                                        type="number"  // Change this to number to accept only numbers
                                        name="increments"
                                        value={projectIncData.increments}
                                        onChange={handleProjectIncData}
                                        placeholder="Enter Increment"
                                    />
                                    <Autocomplete
                                        sx={{ width: '90%', margin: '0px' }}
                                        options={RelaventProjects}  // Array of project names
                                        getOptionLabel={(option) => option ? option : ''}
                                        onChange={(event, newValue) => {
                                            setprojectIncData((prev) => ({
                                                ...prev,
                                                projectname: newValue  // Store the selected project name
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Projects"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    <button disabled={disableSend} className="grid-item-salary grid-item-salary-button" onClick={SendProjectIncData}>Send</button>
                                </>
                            )}
                        </div>
                        {(user.role == 'HR' || user.role == 'Executive') &&
                            <div onClick={() => setopenProjInc(!openProjInc)} className="add-sal-btn" style={{ textAlign: "center", margin: "20px" }}>
                                <GooeyButton > <AddCircleOutlineOutlinedIcon className="add-circle-btn" sx={{ color: "#06c8d9", height: "30px", width: '30px' }} /> Add Project Increment</GooeyButton>
                            </div>
                        }
                    </div>
                    <div className="retainers-increments" style={{ marginBottom: "40px" }}>
                        <div className="heading-large" style={{ textAlign: 'center', width: '30%', padding: '25px', margin: 'auto' }}>
                            Retainers Allowance
                            <hr />
                        </div>
                        <div className="grid-container-retainers-increments">
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increment</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Client Name</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increment Date</strong></div>
                            {Array.isArray(retainerInc) && retainerInc.length > 0 ? (
                                retainerInc.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <div className="grid-item-salary">{item.increment}</div>
                                        <div className="grid-item-salary">{item.clientName}</div>
                                        <div className="grid-item-salary">{new Date(item.incrementDate).toLocaleDateString()}</div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <p style={{textAlign: "center" , alignContent: "center" , margin: "10px"}}>No retainer increments yet.</p>
                            )}
                        </div>
                    </div>
                    {/* {(user.role == 'HR' || user.role == 'Executive') &&
                            <div className="add-sal-btn" style={{ textAlign: "center", margin: "20px" }}>
                                <GooeyButton> <AddCircleOutlineOutlinedIcon className="add-circle-btn" sx={{ color: "#06c8d9", height: "30px", width: '30px' }} /> Add Retainer's Allowance</GooeyButton>
                            </div>
                        } */}
                    {/* <div className="extra-increments">
                        <div className="heading-large" style={{ textAlign: 'center', width: '30%', padding: '25px', margin: 'auto' }}>Extra Allowance<hr /></div>
                        <div class="grid-container-extra-increments">
                            <div className="grid-item-salary grid-item-salary-head"><strong>Increments</strong></div>
                            <div className="grid-item-salary grid-item-salary-head"><strong>Project Name</strong></div>
                            {extraAllowance.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="grid-item-salary">{item[0]}</div>
                                    <div className="grid-item-salary">{item[1]}</div>
                                </React.Fragment>
                            ))}
                        </div>
                        {user.role == 'HR' &&
                            <div className="add-sal-btn" style={{ textAlign: "center", margin: "20px" }}>
                                <GooeyButton> <AddCircleOutlineOutlinedIcon className="add-circle-btn" sx={{ color: "#06c8d9", height: "30px", width: '30px' }} /> Add New Allowance</GooeyButton>
                            </div>
                        }
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SalaryInfo;