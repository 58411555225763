import React, { useState, useEffect } from "react";
import "./Events.css";
import EventsImage from "../../../utilities/eventsImage.svg";
import EventsCake from "../../../utilities/eventsCake.svg";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@mui/material";
import CelebrationIcon from '@mui/icons-material/Celebration';

const Events = (props) => {
  const user = props.user;
  const [birthdays, setBirthdays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [holidayForm, setHolidayForm] = useState({
    name: '',
    fromDate: '',
    toDate: ''
  });

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const response = await fetch('https://hr-backend-72v1.onrender.com/birthdays');
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setBirthdays(data);
      } catch (error) {
        console.error("Error fetching birthdays:", error);
      }
    };
    fetchBirthdays();
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/holidays/upcoming');
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHolidays(data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHolidayForm(prev => ({ ...prev, [name]: value }));
  };

  const handleAddHoliday = async () => {
    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/holidays/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(holidayForm)
      });
      if (!response.ok) {
        throw new Error("Failed to add holiday");
      }
      fetchHolidays();
      handleCloseDialog();
    } catch (error) {
      console.error("Error adding holiday:", error);
    }
  };

  return (
    <>
      <h4 className="myTableHeader animate__animated animate__lightSpeedInLeft" style={{ margin: "20px 0px -30px 5%", borderBottom: "0px" }}>
        Events
      </h4>
      <div className="eventsImage">
        <img src={EventsImage} alt="Events" />
      </div>

      {/* Birthdays */}
      {birthdays.length > 0 && (
        <div className="birthdayContainer">
          <TableContainer component={Paper} className="birthdayInnerContainer" variant="outlined">
            <h4 className="myTableHeader">Birthdays</h4>
            <Table sx={{ minWidth: 650 }}>
              <TableBody>
                {birthdays.map((employee) => (
                  <TableRow className="eventRow" key={employee._id}>
                    <TableCell sx={{ fontSize: "large", width: "100px" }}>
                      <Avatar sx={{ bgcolor: "black", height: "50px", width: "50px" }}>
                        {employee.name[0]}
                      </Avatar>
                    </TableCell>
                    <TableCell sx={{ fontSize: "large" }} align="left">
                      {employee.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large" }}>
                      {new Date(employee.birthday).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ fontSize: "large" }} align="right">
                      <img src={EventsCake} height="40px" alt="Cake" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {/* Holidays */}
      <div className="birthdayContainer">
        <TableContainer component={Paper} className="birthdayInnerContainer" variant="outlined">
          {user.role == 'HR' || user.role == 'Executive' ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="myTableHeader" style={{ width: "80%" }}>Upcoming Holidays</h4>
              <Button variant="contained" onClick={handleOpenDialog}>Add Holiday</Button>
            </div>
          ) : (
            <h4 className="myTableHeader">Upcoming Holidays</h4>
          )}


          <Table sx={{ minWidth: 650 }}>
            <TableBody>
              {holidays.map((holiday, index) => (
                <TableRow className="eventRow" key={index}>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {holiday.name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large" }} align="left">
                    {new Date(holiday.fromDate).toLocaleDateString()} - {new Date(holiday.toDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell sx={{ fontSize: "large", fontWeight: "bold" }} align="right">
                    <CelebrationIcon fontSize="large" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Add Holiday Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Holiday</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Name"
            type="text"
            fullWidth
            variant="outlined"
            name="name"
            value={holidayForm.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="From Date"
            type="date"
            fullWidth
            variant="outlined"
            name="fromDate"
            value={holidayForm.fromDate}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="To Date"
            type="date"
            fullWidth
            variant="outlined"
            name="toDate"
            value={holidayForm.toDate}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddHoliday}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Events;
