import React, { useState, useEffect, useRef } from "react";
import "./employee.css";
import "../button.css";
import Navbar from '../Navbar/navbar';
import Sidebar from './Sidebar/sidebar';
import PersonalDetails from './Personals/PersonalDetails';
import RequestLeave from './RequestLeave/RequestLeave';
import Events from '../HR/Events/Events';
import StartIcon from '../../utilities/start.svg';
import PauseIcon from '../../utilities/pause.svg';
import ResumeIcon from '../../utilities/resume.svg';
import StopIcon from '../../utilities/stop.svg';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TableContainer,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
} from '@mui/material';
// import Dashboard from './Dashboard/Dashboard';



// import {
//   Container,
//   Row,
//   Col,
//   Breadcrumb,
//   Card,
//   ListGroup,
//   ProgressBar,
// } from "react-bootstrap";
// import logo from "../../utilities/logo-white.jpeg";

// import DialogTitle from '@mui/material/DialogTitle';
// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { useTimer } from 'react-timer-hook';

// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import DoneIcon from "@mui/icons-material/Done";
// import { Input } from "@mui/material";

const Employee = (props) => {
  const user = props.user;
  const [selectedOption, setSelectedOption] = useState('projectA');
  const [report, setreport] = useState(true);
  const [profileText, setprofileText] = useState('Show Profile');
  const [profile, setprofile] = useState(false);
  const [Employee, setEmployee] = useState(null);
  const [toggleEditE, settoggleEditE] = useState(false);
  const [toggleEditP, settoggleEditP] = useState(false);
  const [toggleEditA, settoggleEditA] = useState(false);
  const [toggleEditB, settoggleEditB] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [leaveReason, setLeaveReason] = useState('');
  const [reportDetail, setreportDetail] = useState('');
  const [profileChange, setprofileChange] = useState('');
  const [leaveDays, setLeaveDays] = useState('');
  const [open, setOpen] = useState(false);
  const [userLeaves, setUserLeaves] = useState([]);
  const [showUserLeaves, setShowUserLeaves] = useState(false);
  const [reason, setReason] = useState('');
  const [pauseTimestamp, setPauseTimestamp] = useState(null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const [appointmentTaken, setAppointmentTaken] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [pipelineMoved, setPipelineMoved] = useState('');
  const ws = useRef(null);
  const [dashbaordToggle, setDashbaordToggle] = useState(true);
  const [selectedProject, setselectedProject] = useState('');
  const [timerShowToggle, setTimerShowToggle] = useState(true);
  const [myProfileToggle, setmyProfileToggle] = useState(false);
  const [eventsToggle, seteventsToggle] = useState(false);
  const [requestLeaveToggle, setrequestLeaveToggle] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [showMainReport, setShowMainReport] = useState(false);



  const handleProjectDropChange = (event) => {
    setselectedProject(event.target.value);
  }
  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  }



  const dashbaordToggleHandler = () => {
    setTimerShowToggle(true);
    setmyProfileToggle(false);
    seteventsToggle(false);
    setrequestLeaveToggle(false);
  };
  const profileToggleHandler = () => {
    setTimerShowToggle(false);
    setmyProfileToggle(true);
    seteventsToggle(false);
    setrequestLeaveToggle(false);
  };
  const eventsToggleHandler = () => {
    setTimerShowToggle(false);
    setmyProfileToggle(false);
    seteventsToggle(true);
    setrequestLeaveToggle(false);
  };


  const requestALeaveToggleHandler = () => {
    setTimerShowToggle(false);
    seteventsToggle(false);
    // setDashbaordToggle(false);
    setmyProfileToggle(false);
    setrequestLeaveToggle(true);
  };

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLeaveChange = (event) => {
    setSelectedLeave(event.target.value);
    setIsDialogOpen(true);
  };

  const handleAppointmentTakenChange = (event) => {
    setAppointmentTaken(event.target.value);
  };

  const handleEmailSentChange = (event) => {
    setEmailSent(event.target.value);
  };

  const handlePipelineMovedChange = (event) => {
    setPipelineMoved(event.target.value);
  };

  const handleStopClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmSubmit = () => {
    handleStop();
    setShowMainReport(true);
    setOpenDialog(false);
  };

  const handleShowMyLeaves = async () => {
    try {
      const response = await fetch(`https://hr-backend-72v1.onrender.com/leaves?email=${user.email}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user leaves');
      }
      const data = await response.json();
      setUserLeaves(data);
      setShowUserLeaves(true); // Open the dialog when leaves are fetched
      setOpen(true);
    } catch (error) {
      console.error('Error fetching user leaves:', error);
    }
  };

  const handleShowReason = (reason) => {
    setSelectedLeave(reason); // Set the selected leave's reason to display
  };

  const handleClose = () => {
    setOpen(false);
    setShowUserLeaves(false);
  };

  const handleClickOpen = (reason) => {
    setReason(reason);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setLeaveReason('');
    setLeaveDays('');
  };

  const handleReportClose = () => {
    setreport(false);
  };

  const handleReasonChange = (event) => {
    setLeaveReason(event.target.value);
  };

  const handleReportDetail = (event) => {
    setreportDetail(event.target.value);
  };
  const handleProfileChange = (event) => {
    setprofileChange(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleProfileShow = () => {
    if (!profile) {
      setprofileText("Close Profile");
    } else {
      setprofileText("Show Profile");
    }
    setprofile(!profile);
  };

  const handleDaysChange = (event) => {
    setLeaveDays(event.target.value);
  };

  const handleReportSubmit = async () => {
    try {
      let reportData;

      if (selectedProject === 'MasterMind') {
        reportData = {
          name: user.name,
          email: user.email,
          appointmentsTaken: appointmentTaken,
          emailSent,
          pipelineMoved,
          date: new Date(),
        };

        const response = await fetch('https://hr-backend-72v1.onrender.com/projecta', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reportData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.text();
        console.log('MasterMind Response:', responseData);

      } else if (selectedProject === 'Progress Report') {
        reportData = {
          name: user.name,
          email: user.email,
          reportDetail,
        };

        const response = await fetch('https://hr-backend-72v1.onrender.com/reports', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reportData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.text();
        console.log('Progress Report Response:', responseData);
      }
      alert("Report Submitted Successfully");
      window.location.reload();

    } catch (error) {
      alert("There Was an Error Submitting Your Request");
      console.error('Error submitting report:', error);
    }
  };


  const handleProfileChangeSubmit = async () => {
    if (!profileChange.trim()) {
      alert('Profile change request detail cannot be empty.');
      return;
    }

    const response = await fetch('https://hr-backend-72v1.onrender.com/requestProfileChange', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: Employee.email,
        changeRequest: profileChange,
      }),
    });

    const result = await response.json();

    if (result.success) {
      alert('Profile change request submitted successfully.');
      setprofileChange('');
    } else {
      alert('Failed to submit profile change request.');
    }
  };

  const handleSubmit = async () => {
    if (!selectedLeave || !leaveReason || !leaveDays) {
      alert('Please fill in all fields.');
      return;
    }

    const leaveData = {
      leaveType: selectedLeave,
      email: user.email,
      date: new Date().toISOString(),
      requestedDays: leaveDays,
      reason: leaveReason,
      name: user.name,
      status: 'Pending'
    };

    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/applyleave', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leaveData),
      });

      if (response.ok) {
        alert('Leave applied successfully.');
        handleDialogClose();
      } else {
        const errorData = await response.json();
        console.error('Error applying leave:', errorData);
        alert('Failed to apply leave. Please try again.');
      }
    } catch (error) {
      console.error('Error applying leave:', error);
      alert('Failed to apply leave. Please try again.');
    }
  };

  const inputEditHandler = () => {
    settoggleEditE(false);
    settoggleEditP(false);
    settoggleEditA(false);
    settoggleEditB(false);
  };

  const handleLogout = () => {
    beforeUnloadHandler();
    // console.log(user);
    localStorage.removeItem("userValue");
    window.location.reload();
  };

  const employee = {
    name: user.name,
    email: "example@example.com",
    phone: "0900 78601",
    address: "California , USA",
    bank: "Easypaisa / 03123456789",
    joining: "1 January, 2024",
    designation: "Junior Developer",
    salary: 25000,//
  };

  useEffect(() => {//
    const loginEmployee = async () => {
      try {
        const response = await fetch(`https://hr-backend-72v1.onrender.com/loginemployee?email=${user.email}`);
        const data = await response.json();

        if (response.ok) {
          // console.log('Login successful:', data);
          setEmployee(data);
        } else {
          console.error('Login failed:', data.message);
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    };

    loginEmployee();
  }, [user.email]);


  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/service-worker.js', { scope: '/Employee/' })
  //       .then((registration) => {
  //         console.log('Service Worker registered with scope:', registration.scope);
  //       })
  //       .catch((error) => {
  //         console.log('Service Worker registration failed:', error);
  //       });
  //   }
  // }, [])

  useEffect(() => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      // Listener for messages from the service worker (optional for handling updates)
      const swListener = (event) => {
        if (event.data.type === 'timerUpdate' && event.data.email === user.email) {
          // console.log("Update received");
          setTime(event.data.time); // Update time based on the service worker's data
          console.log('Timer update received:', event.data.time);
        }
      };

      // Add the listener for service worker messages
      navigator.serviceWorker.addEventListener('message', swListener);

      return () => {
        navigator.serviceWorker.removeEventListener('message', swListener);
      };
    }
  }, [user.email]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  // useEffect(() => {
  //   if (isRunning && !isPaused) {
  //     console.log(`Timer is running: ${hours}h ${minutes}m ${seconds}s`);
  //   }
  // }, [time, isRunning, isPaused, hours, minutes, seconds]);

  // Send timer actions to the service worker
  useEffect(() => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      if (isRunning && !isPaused) {
        // Start the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'startTimer',
          name: user.name,
          email: user.email,
          time,
          isRunning: true,
          isPaused: false,
        });
      } else if (isPaused) {
        // Pause the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'pauseTimer',
          name: user.name,
          email: user.email,
          isRunning: isRunning,
          isPaused: true,
        });

        // Alert after 1 minute of being paused
        timeoutRef.current = setTimeout(() => {
          if (isPaused && !isStopped) {
            navigator.serviceWorker.controller.postMessage({
              action: 'alert',
              email: user.email,
              message: 'User has not resumed the timer after 1 minute',
            });
          }
        }, 60000);
      } else if (!isRunning && !isStopped) {
        // Resume the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'resumeTimer',
          name: user.name,
          email: user.email,
          isRunning: true,
          isPaused: false,
        });
      } else if (isStopped) {
        // Stop the timer
        navigator.serviceWorker.controller.postMessage({
          action: 'stopTimer',
          name: user.name,
          email: user.email,
          isRunning: false,
          isPaused: true,
        });
      }
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isRunning, isPaused, isStopped, time, user.email]);

  // Start the timer
  const handleStart = () => {
    setIsRunning(true);
    setIsPaused(false);
    setIsStopped(false);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'startTimer',
        name: user.name,
        email: user.email,
        time,
        isRunning: true,
        isPaused: false,
      });
    }
  };

  // Pause the timer
  const handlePause = () => {
    setIsPaused(true);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'pauseTimer',
        name: user.name,
        email: user.email,
        isRunning: isRunning,
        isPaused: true,
      });
    }
  };

  // Resume the timer
  const handleResume = () => {
    setIsPaused(false);
    setIsStopped(false);
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'resumeTimer',
        name: user.name,
        email: user.email,
        isRunning: true,
        isPaused: false,
      });
    }
    clearTimeout(timeoutRef.current);
  };

  // Stop the timer
  const handleStop = async () => {
    setIsRunning(false);
    setIsPaused(false);
    setIsStopped(true);

    const formattedTime = formatTime(time);
    const timerData = {
      email: user.email,
      name: user.name,
      date: new Date().toISOString(),
      time: formattedTime,
    };

    try {
      const response = await fetch('https://hr-backend-72v1.onrender.com/storeTimerData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(timerData),
      });

      if (response.ok) {
        console.log('Timer data stored successfully');
      } else {
        console.error('Failed to store timer data');
      }
    } catch (error) {
      console.error('Error storing timer data:', error);
    }

    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'stopTimer',
        name: user.name,
        email: user.email,
        isRunning: false,
        isPaused: false,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  const beforeUnloadHandler = (event) => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.ready) {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          action: 'stopTimer',
          name: user.name,
          email: user.email,
          isRunning: false,
          isPaused: true,
        });
      }
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then((boolean) => {
          console.log(`Service worker unregistered: ${boolean}`);
        });
      });
    }
  };

  const formatTime = (time) => {
    if (typeof time !== 'number' || isNaN(time)) {
      return '00:00:00';
    }
    return new Date(time * 1000).toISOString().substr(11, 8);
  };



  return (
    <section>
      <div className="employee">
        <Sidebar
          onShowProfile={profileToggleHandler}
          onShowTimer={dashbaordToggleHandler}
          onShowEvents={eventsToggleHandler}
          onShowRequestLeave={requestALeaveToggleHandler}
          myProfileToggle={myProfileToggle}
          timerShowToggle={timerShowToggle}
          eventsToggle={eventsToggle}
          requestLeaveToggle={requestLeaveToggle}
        />

        <div className="content">
          <Navbar user={user} />

          {timerShowToggle &&
            <div className="timer">
              <p>T H E<span className="countdown-space"></span>C O U N T D O W N</p>

              <div className="countdown-timer">
                <div className="hours">
                  <div className="timer-blocks">
                    <p>{Math.floor(time / 3600)}</p>
                  </div>
                  <p>HRS</p>
                </div>
                <div className="colons">
                  <div className="rectangles">
                  </div>
                  <div className="rectangles">
                  </div>
                </div>
                <div className="minutes">
                  <div className="timer-blocks">
                    <p>{Math.floor((time % 3600) / 60)}</p>
                  </div>
                  <p>MIN</p>
                </div>
                <div className="colons">
                  <div className="rectangles">
                  </div>
                  <div className="rectangles">
                  </div>
                </div>
                <div className="seconds">
                  <div className="timer-blocks">
                    <p>{time % 60}</p>
                  </div>
                  <p>SEC</p>
                </div>
              </div>
              {!showMainReport && (
                <div className="toggles">
                  <button onClick={handleStart} className="start toggle-btns">
                    Start
                    <img className="toggleImgs" src={StartIcon} alt="Start Icon" width="24" height="24" />
                  </button>
                  <button onClick={handlePause} className="pause toggle-btns">
                    Pause
                    <img className="toggleImgs" src={PauseIcon} alt="Pause Icon" width="24" height="24" />
                  </button>
                  <button onClick={handleResume} className="resume toggle-btns">
                    Resume
                    <img className="toggleImgs" src={ResumeIcon} alt="Resume Icon" width="24" height="24" />
                  </button>
                  <button className="stop toggle-btns" onClick={handleStopClick}>
                    Stop
                    <img className="toggleImgs" src={StopIcon} alt="Stop Icon" width="24" height="24" />
                  </button>
                </div>
              )}
              {showMainReport && (
                <div className="main-report">
                  <div className="submit-report-head">
                    <p>Submit Report</p>
                  </div>

                  <div className="project-type">
                    <FormControl fullWidth>
                      <InputLabel id="progress-report-submission">Mastermind / Progress Report</InputLabel>
                      <Select
                        labelId="progress-report-submission"
                        id="progress"
                        value={selectedProject}
                        label="Mastermind / Progress Report"
                        onChange={handleProjectDropChange}
                      >
                        <MenuItem value="MasterMind">MasterMind</MenuItem>
                        <MenuItem value="Progress Report">Progress Report</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {selectedProject === 'MasterMind' && (
                    <div className="mastermind-submit">
                      <div className="appointments">
                        <TextField
                          margin="dense"
                          id="appointmentTaken"
                          label="Appointment Taken"
                          type="number"
                          fullWidth
                          value={appointmentTaken}
                          onChange={handleAppointmentTakenChange}
                        />
                      </div>
                      <div className="emailsent">
                        <FormControl fullWidth>
                          <InputLabel id="email-sent">Email Sent</InputLabel>
                          <Select
                            labelId="email-sent"
                            id="email-sent"
                            value={emailSent}
                            label="Email Sent"
                            onChange={handleEmailSentChange}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="pipelines">
                        <FormControl fullWidth>
                          <InputLabel id="pipeline-moved">Pipeline Moved</InputLabel>
                          <Select
                            labelId="pipeline-moved"
                            id="pipeline-moved"
                            value={pipelineMoved}
                            label="Pipeline Moved"
                            onChange={handlePipelineMovedChange}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {selectedProject === 'Progress Report' && (
                    <div className="progress-report-submit">
                      <TextField
                        margin="dense"
                        id="reportdetail"
                        label="Report Detail"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={reportDetail}
                        onChange={handleReportDetail}
                        sx={{ flex: 1 }}
                      />
                    </div>
                  )}
                  <div className="report-submit">
                    <button className="submit-btn" onClick={handleReportSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              )}


            </div>
          }
          {/* MY PROFILE COMPONENT SHOULD BE SHOWN HERE........ */}
          {myProfileToggle &&
            <>
              {/* <h1 className="heading">Profile Section Coming Soon !</h1> */}
              <PersonalDetails
                user={user}
                Employee={Employee}
                changeEmployeeInfo={handleEmployeeChange}
              />
            </>
          }
          {eventsToggle &&
            // <h1 className="heading">Events Coming Soon !</h1>
            <Events
              user={user}
            />
          }
          {requestLeaveToggle &&
            <RequestLeave
              user={user}
              Employee={Employee}
            />
          }
        </div>



      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you ready to Submit Progress Report?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm if you're ready to submit the progress report.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Employee;


