import React, { useState, useEffect } from "react";

import "./RequestLeave.css";

const RequestLeave = (props) => {

  return (
    <>
      <h1 className="heading">Request to Leave Coming Soon !</h1>
    </>
  );
};

export default RequestLeave;
